import { PencilIcon, TrashIcon } from '@heroicons/react/24/solid'
import { Button, ConfirmationDialog } from 'common'
import moment from 'moment'
import { useDeleteNote } from 'services/notes/deleteNote'

interface ColumnsProps {
	setShowNotesForm: (open: boolean) => void
	setNoteID: (id: string) => void
	showNotes?: boolean
}

const Columns = ({
	setShowNotesForm,
	setNoteID,
	showNotes = true,
}: ColumnsProps) => {
	const { deleteNote } = useDeleteNote()

	return [
		{
			header: 'Created Date',
			field: 'createdDate',
			body: (row: { createdDate: Date }) => {
				return row.createdDate
					? moment(row.createdDate, 'DD/MM/YYYY').format('DD/MM/YYYY')
					: ''
			},
		},
		{
			header: 'File Name',
			field: 'fileName',
		},
		{
			header: 'File Type',
			field: 'fileType',
		},
		{
			header: 'File Description',
			field: 'fileDescription',
		},
		...(showNotes
			? [
					{
						header: 'Notes',
						field: 'notes',
					},
			  ]
			: []),
		{
			header: 'File',
			field: 'fileUrl',
			body: (row: { fileUrl: string }) => {
				return row.fileUrl != '' ? (
					<a className="text-blue-600" href={row.fileUrl}>
						Link
					</a>
				) : (
					<a>Invalid Link</a>
				)
			},
		},
		{
			field: 'id',
			header: 'Edit',
			body: (row: { id: string }) => {
				return row.id != null ? (
					<Button
						type="button"
						variant="primary"
						onClick={() => {
							setNoteID(row.id)
							setShowNotesForm(true)
						}}
						size="sm">
						<PencilIcon className=" h-4 w-4" />
					</Button>
				) : (
					<div></div>
				)
			},
		},
		{
			field: 'Delete',
			header: 'Delete',
			body: (row: { id: number }) => {
				return row.id != null ? (
					<ConfirmationDialog
						icon="danger"
						title="Delete Task"
						body="Are you sure you want to delete this task? This action is unrecoverable!"
						triggerButton={
							<button type="button">
								<TrashIcon className="h-4 w-4 text-gray-500" />
							</button>
						}
						confirmButton={
							<Button
								size="sm"
								variant="danger"
								onClick={async () => deleteNote(row.id)}>
								Delete
							</Button>
						}
					/>
				) : (
					<div></div>
				)
			},
		},
	]
}

export default Columns
