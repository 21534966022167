import { Image, Text, View } from '@react-pdf/renderer'
import { AppConfig } from 'config'
import * as logo from 'assets/logo.png'
import { stylesHeading } from './styles'

export const Heading = () => {
	return (
		<View style={stylesHeading.headingContainer}>
			<Image style={stylesHeading.logo} src={logo.default} />
			<View style={stylesHeading.headingContact}>
				<View style={stylesHeading.headingDisplay}>
					<Text style={stylesHeading.headingText}>iScaff Ltd</Text>
					<Text style={stylesHeading.subText}>M:0277490248</Text>
				</View>
				<View style={stylesHeading.headingDisplay}>
					<Text style={stylesHeading.headingText}>9 Makaro Street</Text>
					<Text style={stylesHeading.subText}>grant@iscaff.co.nz</Text>
				</View>
				<View style={stylesHeading.headingDisplay}>
					<Text style={stylesHeading.headingText}>Elsdon</Text>
					<Text style={stylesHeading.subText}>www.iscaff.co.nz</Text>
				</View>
				<View style={stylesHeading.headingDisplay}>
					<Text style={stylesHeading.headingText}>Porirua 5022</Text>
					<Text style={stylesHeading.subText}>GST: 107011781</Text>
				</View>
			</View>
		</View>
	)
}
