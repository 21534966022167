import { PencilIcon } from '@heroicons/react/24/solid'
import { Spinner, Table, Badge } from 'common'
import { VisitForm } from 'components/Visits'
import { AppRoutes } from 'config'
import { useMemo, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { VisitServices } from 'services'

interface VisitsTableProps {
	job_id?: number
}

export const VisitsTable = ({ job_id }: VisitsTableProps) => {
	const location = useLocation()
	const { data, isLoading } = VisitServices.useVisitsByJobId(job_id)
	const activeVisits = data?.filter(
		(visit: { status: string }) => visit.status === 'Active'
	)
	const [showVisitForm, setShowVisitForm] = useState(false)
	if (isLoading) {
		return <Spinner />
	}

	console.log({ activeVisits })

	const columns = [
		{ field: 'date', header: 'Date' },
		{
			field: 'teamLeaderData',
			header: 'Team Leader',
			body: (row: { teamLeaderData: { staff_name: string } }) => {
				return <>{row.teamLeaderData.staff_name}</>
			},
		},
		{
			field: 'task_list',
			header: 'Tasks',
			body: (row: { task_list: string }) => {
				const tasks = row.task_list.split(', ').map((task: string) => (
					<span key={task}>
						{task}
						<br />
					</span>
				))
				return <>{tasks}</>
			},
		},
		{ field: 'type', header: 'Type' },
		{
			field: 'status',
			header: 'Status',
			body: (row: { status: string }) => {
				return <Badge text={row.status} type={row.status} />
			},
		},
		{
			field: 'visit_status',
			header: 'Visit Status',
		},
		{
			field: 'visit_sqm',
			header: 'SQM Change',
		},
		{
			field: 'total_hours',
			header: 'Total Hours',
		},
		{
			field: 'notes',
			header: 'Notes',
			bodyClassName: 'w-60',
		},
		{
			field: 'Edit',
			header: 'Edit',
			body: (row: { id: number }) => (
				<Link
					to={{
						pathname: AppRoutes.privateRoutes.visitsEdit.replace(
							':id',
							row.id.toString()
						),
					}}
					state={{ background: location, name: 'editVisit' }}>
					<PencilIcon className="h-4 w-4 text-gray-500" />
				</Link>
			),
		},
	]

	return (
		<>
			<Table
				columns={columns}
				data={activeVisits}
				isLoading={isLoading ?? false}
				title="Visit Scheduler"
				ActionName="Schedule Visit"
				setOpen={setShowVisitForm}
				disableButtons
			/>
			<VisitForm
				heading="Schedule Visit"
				formType="create"
				open={showVisitForm}
				setOpen={setShowVisitForm}
				job_id={job_id}
			/>
		</>
	)
}
