import React, { useEffect, useState } from 'react'
import { Checkbox } from 'common' // Import the updated Checkbox component

interface CheckboxOption {
	type: string
	title: string
	checked: boolean
}

interface TickBoxProps {
	sharedCheckBoxes: CheckboxOption[]
	setsharedCheckBoxes: any
}

const TickBox: React.FC<TickBoxProps> = ({
	sharedCheckBoxes,
	setsharedCheckBoxes,
}) => {
	const handleCheckboxChange = (id: string, values: string[]) => {
		const updatedState = sharedCheckBoxes.map((cb) => {
			if (cb.type === 'Additional Condition' && id === 'additionalConditions') {
				return { ...cb, checked: values.includes(cb.title) }
			}
			return cb
		})

		setsharedCheckBoxes(updatedState)
	}

	const options = sharedCheckBoxes.map((cb) => ({
		value: cb.title,
		label: cb.title,
		type: cb.type,
		checked: cb.checked,
	}))

	const selectedConditions = sharedCheckBoxes
		.filter((cb) => cb.checked && cb.type === 'Additional Condition')
		.map((cb) => cb.title)

	return (
		<div className="grid gap-4">
			<br />
			<h2 className="text-lg leading-6 font-sm uppercase text-gray-700 my-4">
				Select Additional Conditions
			</h2>
			<Checkbox
				title=""
				options={options.filter((opt) => opt.type === 'Additional Condition')}
				id="additionalConditions"
				values={selectedConditions}
				onChange={handleCheckboxChange}
			/>
		</div>
	)
}

export default TickBox
