import { Font, StyleSheet } from '@react-pdf/renderer'

Font.register({
	family: 'Open Sans',
	src: 'https://fonts.gstatic.com/s/opensans/v23/mem8YaGs126MiZpBA-UFVZ0e.ttf',
})

export const stylesFooter = StyleSheet.create({
	footer: {
		flexDirection: 'row',
		position: 'absolute',
		bottom: 7,
		right: 0,
	},
	footerLogo: {
		objectFit: 'contain',
		width: '110%',
		marginRight: 0,
	},
	pageNumber: {
		marginTop: 10,
		marginRight: 17,
		fontSize: 8,
		textAlign: 'center',
		color: 'grey',
	},
})
