import { useState } from 'react'
import { FilterMatchMode } from 'primereact/api'
import { DataTable } from 'primereact/datatable'
import { DataTableServices, JobsServices } from 'services'
import { Container, DataTableHeader, PageHeading, Badge } from 'common'
import { tableFilterMatchModeOptions } from 'services/DataTable'
import { Column } from 'primereact/column'
import { Link, useLocation } from 'react-router-dom'
import { PencilIcon, FolderIcon, PlusIcon } from '@heroicons/react/24/solid'
import { AppRoutes } from 'config'
import { JobsComponents } from 'components'
import moment from 'moment'
import { PageJobHeading } from 'common/PageJobHeader'
import { branchOptions } from 'models'

const getDescendantProp = (obj: any, desc: string): any => {
	let arr = desc.split('.')
	while (arr.length && (obj = obj[arr.shift() || 0]));
	return obj
}

const sortNZDate = (
	dataToSort: any[],
	field: string,
	order: number | null | undefined
): any[] => {
	try {
		const sortData = dataToSort.map((data: any) => {
			// Handle fields that contain things like subData.field
			const date = getDescendantProp(data, field)

			try {
				// Convert DD/MM/YYYY to YYYY-MM-DD for Date constructor
				const timestamp = new Date(
					date.split('/').reverse().join('/')
				).getTime()
				if (isNaN(timestamp)) {
					throw new Error('Invalid Date')
				}
				return { data, timestamp }
			} catch {
				return { data, timestamp: 0 } // Handle invalid dates
			}
		})

		const sortOrder = order || 1 // Default to ascending order if undefined
		// Sort based on sort order
		sortData.sort((a: any, b: any) => {
			if (sortOrder === 1) {
				return a.timestamp - b.timestamp
			} else {
				return b.timestamp - a.timestamp
			}
		})

		// Return the original data with the new sort order
		return sortData.map((item: any) => item.data)
	} catch {
		return dataToSort // Return unsorted data in case of error
	}
}

export const JobsInvoicesTable = () => {
	const location = useLocation()
	const { data, isLoading, enableCreateUpdate } = JobsServices.useJobs()
	const { data: handoverData, isLoading: handoverLoading } =
		JobsServices.useHandovers()
	// const [openJobForm, setOpenJobForm] = useState(false)
	const [openDayWorksJobForm, setOpenDayWorksJobForm] = useState(false)

	const jobDataWithHandoverData = data
		?.map((job: any) => {
			if (handoverData === undefined) return job
			const handover = handoverData?.find(
				(handover: any) => Number(handover.job_id) === Number(job.id)
			)
			return {
				...job,
				handoverData: handover,
				confined_spaces:
					job.job_type === 'Confined Spaces' ? 'Confined Space' : 'No',
				work_safe:
					handover?.work_safe == 'Yes'
						? 'Yes'
						: handover?.work_safe == 'Not Required'
						? 'Not Required'
						: handover?.work_safe == 'No'
						? 'No'
						: '?',
				start_date: moment(new Date(job.start_date)).format('DD/MM/YYYY'),
				formattedWorksafeExpiryDate: handover?.worksafe_uploaded_when
					? moment(handover?.worksafe_uploaded_when, 'DD/MM/YYYY').toDate()
					: null,
				quote_num: job.quote_id ? `${Number(job.quote_id) + 1000}` : null,
			}
		})
		.filter((job: any) => {
			const isInvoiced = job.job_status === 'Invoiced'
			const invoiceDate = job.invoice_date
				? moment(job.invoice_date, 'DD/MM/YYYY')
				: null
			return isInvoiced ? invoiceDate?.isSameOrAfter(moment(), 'month') : true
		})

	const {
		clearFilter,
		filters,
		globalFilterValue,
		setFilters,
		setGlobalFilterValue,
		globalFilterFields,
		FilterColumn,
		dataTableReference,
	} = DataTableServices.useFiltersDataTable({
		initialFilters: [
			{
				filterName: 'job_status',
				filterInitialValue: null,
				filterInitialMatchMode: FilterMatchMode.EQUALS,
				filterOptionsMatchOptions: tableFilterMatchModeOptions.equalsOrNot,
				filterOptions: [
					'Pending Handover',
					'In Progress',
					'Completed',
					'Invoiced',
				],
			},
			{
				filterName: 'status',
				filterInitialValue: 'Active',
				filterInitialMatchMode: FilterMatchMode.EQUALS,
				filterOptionsMatchOptions: tableFilterMatchModeOptions.equalsOrNot,
				filterOptions: ['Active', 'Inactive'],
			},
			{
				filterName: 'on_hire',
				filterInitialValue: '',
				filterInitialMatchMode: FilterMatchMode.EQUALS,
				filterOptionsMatchOptions: tableFilterMatchModeOptions.equalsOrNot,
				filterOptions: ['Yes', 'No'],
			},
			{
				filterName: 'confined_spaces',
				filterInitialValue: '',
				filterInitialMatchMode: FilterMatchMode.EQUALS,
				filterOptionsMatchOptions: tableFilterMatchModeOptions.equalsOrNot,
				filterOptions: ['Confined Space', 'No'],
			},
			{
				filterName: 'work_safe',
				filterInitialValue: '',
				filterInitialMatchMode: FilterMatchMode.EQUALS,
				filterOptionsMatchOptions: tableFilterMatchModeOptions.equalsOrNot,
				filterOptions: ['Yes', 'Not Required', 'No', '?'],
			},
			{
				filterName: 'handoverData.invoice_manager',
				filterInitialValue: '',
				filterInitialMatchMode: FilterMatchMode.CONTAINS,
				filterOptionsMatchOptions: tableFilterMatchModeOptions.containsOrNot,
				filterOptions: [],
			},
			{
				filterName: 'branch',
				filterInitialValue: null,
				filterInitialMatchMode: FilterMatchMode.EQUALS,
				filterOptionsMatchOptions: tableFilterMatchModeOptions.equalsOrNot,
				filterOptions: ['Wellington'],
			},
		],
		additionalGlobalFilterFields: [
			'site',
			'job_num',
			'job_type',
			'start_date',
			'descriptionOfQuote',
			'clientData.client_name',
			'handoverData.worksafe_uploaded_when',
			'branch',
			'inactivated_by',
			'quote_num',
			'handoverData.invoice_manager',
		],
	})

	const header = DataTableHeader({
		clearFilter,
		globalFilterValue,
		filters,
		setFilters,
		setGlobalFilterValue,
		dataTableReference,
	})

	return (
		<div className="card">
			<PageJobHeading
				title="Jobs to Invoice"
				// createBtn="Schedule Visit"
				isEditable={false}
				// secondCreateBtn="Create Job"
				// secondSetOpen={setOpenDayWorksJobForm}
			/>
			<Container>
				<DataTable
					ref={dataTableReference}
					value={jobDataWithHandoverData}
					paginator
					showGridlines
					rows={100}
					paginatorPosition="top"
					rowsPerPageOptions={[25, 50, 100]}
					loading={isLoading || handoverLoading}
					dataKey="id"
					sortField="id"
					sortOrder={-1}
					filters={filters}
					globalFilterFields={globalFilterFields}
					header={header}
					stripedRows
					emptyMessage="No jobs found.">
					<Column
						field="job_num"
						header="Job #"
						style={{ width: '7rem' }}
						sortable
						body={(rowData) => (
							<Link
								to={AppRoutes.privateRoutes.Invoices.replace(':id', rowData.id)}
								className="flex items-center"
								state={{ job_num: rowData.job_num }}>
								<FolderIcon className="h-4 w-4 mx-2" />
								<># {rowData.job_num}</>
							</Link>
						)}
					/>
					<Column
						field="clientData.client_name"
						header="Client"
						style={{ maxWidth: '280px', textAlign: 'left' }}
					/>
					<Column
						field="descriptionOfQuote"
						header="Description"
						style={{ maxWidth: '400px', textAlign: 'left' }}
					/>
					<Column
						field="job_type"
						header="Job Type"
						style={{ maxWidth: '150px', textAlign: 'left' }}
					/>
					<Column
						field="job_status"
						header="Job Status"
						body={(row: { job_status: string }) => {
							let color
							switch (row.job_status) {
								case 'Completed':
									color = 'Red'
									break
								case 'In Progress':
									color = 'Yellow'
									break
								case 'Invoiced':
									color = 'Green'
									break
								default:
									color = 'Gray'
							}
							return <Badge type={color} text={String(row.job_status)} />
						}}
						{...FilterColumn.job_status}
					/>
					<Column field="on_hire" header="On Hire" {...FilterColumn.on_hire} />
					<Column
						field="notes"
						header="Notes"
						style={{ maxWidth: '280px', textAlign: 'left' }}
					/>
					<Column field="invoice_date" header="Invoice Date" />
					<Column field="invoice_number" header="Invoice Number" />
					{/* <Column
						field="handoverData.invoice_manager"
						header="Invoice Manager"
						body={(rowData) => (
							<>{rowData?.handoverData?.invoice_manager || 'Unassigned'}</>
						)}
						filter
						showFilterOperator={false}
					/> */}
					<Column
						field="quote_num"
						header="Quote #"
						style={{ width: '7rem' }}
						sortable
						body={(rowData) => {
							if (rowData.quote_num) {
								return (
									<Link
										to={AppRoutes.privateRoutes.QuotesPDF.replace(
											':id',
											rowData.quote_id
										)}
										className="flex items-center">
										<FolderIcon className="h-4 w-4 mx-2" />
										<># {rowData.quote_num}</>
									</Link>
								)
							}
							return <div className="flex items-center">N/A</div>
						}}
					/>
					<Column
						field="status"
						header="Status"
						bodyStyle={{ textAlign: 'center' }}
						body={(rowData) => (
							<Badge text={rowData.status} type={rowData.status} />
						)}
						{...FilterColumn.status}
					/>
					<Column field="branch" header="Branch" {...FilterColumn.branch} />
					{enableCreateUpdate && (
						<Column
							header="Edit"
							body={(rowData) => (
								<Link
									to={{
										pathname: AppRoutes.privateRoutes.JobEdit.replace(
											':id',
											rowData.id
										),
									}}
									state={{ background: location, name: 'editJob' }}>
									<PencilIcon className="text-gray-600 h-4 w-4" />
								</Link>
							)}
						/>
					)}
				</DataTable>
			</Container>
		</div>
	)
}
