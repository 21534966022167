import { Image, Text, View } from '@react-pdf/renderer'
import * as footer from 'assets/footer.png'
import { stylesFooter } from './styles'

export const Footer = () => {
	return (
		<View style={stylesFooter.footer}>
			<Image style={stylesFooter.footerLogo} src={footer.default} />
			<Text
				style={stylesFooter.pageNumber}
				render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
			/>
		</View>
	)
}
