export const AdditionalConditionOptions = [
	{
		title: 'Roof structures',
		text: 'This quotation allows for plank protection to roofs, canopies, decks, verandas, or any other existing structures on which scaffolding needs to be erected. However, iScaff assumes these structures can support the scaffold and workload. It is on the sole discretion of the client to ensure the strength of the structures by seeking engineering advice at the client’s own costs prior to erection. Working on roofs can cause dents, cracks, scratches etc; we will take the utmost care to avoid this, but take no responsibility if any damage should occur, as roofs are generally not designed for being worked on.',
		type: 'Additional Condition',
		checked: false,
	},
	{
		title: 'Power lines',
		text: 'Government regulations prohibit the erection and use of any scaffolding closer than 4m to power lines without the power lines being sleeved or disconnected and without the consent from the property owner. Unless the client otherwise advises, with acceptance of this quote the property owner automatically has given consent for work to take place near the power lines. The minimum charge for an electrician to inspect the site and sleeve/de-sleeve the lines is $250 plus GST. In case the public power lines run along the property Wellington Electricity is in charge and further costs may apply including any traffic management etc. All costs will be an additional charge to this quote. All users of the scaffold must follow the instructions on the Electrical Safety Tag in the vicinity of the power lines.',
		type: 'Additional Condition',
		checked: false,
	},
	{
		title: 'Plants',
		text: 'Any plants closer than 1m to the walls need to be trimmed by the client prior to commencement of scaffolding.',
		type: 'Additional Condition',
		checked: false,
	},
	{
		title: 'Satellite dishes / aerials',
		text: 'Scaffolding may interfere with the TV reception and/or the dish/aerial is in the way of the scaffolding. This quote does not include relocation of any sat dish/aerial. However, iScaff is happy to assist in this matter.',
		type: 'Additional Condition',
		checked: false,
	},
	{
		title: 'Ties and fixing',
		text: 'To secure the scaffolding wall ties need to be periodically screwed into the building structure and will be removed on dismantling. Any remedial work will be the client’s responsibility.',
		type: 'Additional Condition',
		checked: false,
	},
	{
		title: 'Garages, driveways and entrances',
		text: 'Parts of the scaffolding will block the entrance, garage, car park and/or driveway while the scaffolding is in place.',
		type: 'Additional Condition',
		checked: false,
	},
	{
		title: 'Neighbours left',
		text: 'iScaff needs to erect some scaffolding on the left side of the house onto the neighbour’s ground. It is the client’s responsibility to obtain permission from the neighbours to allow iScaff access to erect, dismantle and transport parts of scaffolding on their property prior to commencement of scaffolding erection.',
		type: 'Additional Condition',
		checked: false,
	},
	{
		title: 'Neighbours right',
		text: 'iScaff needs to erect some scaffolding on the right side of the house onto the neighbour’s ground. It is the client’s responsibility to obtain permission from the neighbours to allow iScaff access to erect, dismantle and transport parts of scaffolding on their property prior to commencement of scaffolding erection.',
		type: 'Additional Condition',
		checked: false,
	},
	{
		title: 'Council permits',
		text: 'A Corridor Access Request (CAR) is required when any work commences on public road assets such as footpaths, carriageways and berms. The CAR should be generated by the principal of the site and is to be set up via www.submitica.co.nz. Once the CAR is in place please liaise with iScaff who will then add the Road Usage License (RUL). For a service fee of $490 plus GST iScaff can set up the CAR on behalf of the principal. Please note: This quote does not include costs for any further requirements of the council to the scaffolding construction as per description above and/or any traffic management costs or any other costs.',
		type: 'Additional Condition',
		checked: false,
	},
	{
		title: 'Council parking permit',
		text: 'A parking permit for parking the iScaff truck in front of the jobsite needs to be in place prior to the commencement of the erection and dismantle of the job. This can be obtained by the client or by iScaff. For a service fee of $250 plus GST iScaff can organise the permit. Please note: This quote does not include any costs (e.g. traffic management, council fees) for receiving the parking permit.',
		type: 'Additional Condition',
		checked: false,
	},
	{
		title: 'Engineering',
		text: 'Any costs of engineering for e.g. designs and/or certificates requested by the client or by any authorities are not included in this quote.',
		type: 'Additional Condition',
		checked: false,
	},
	{
		title: 'Foundation ties',
		text: 'To secure the scaffolding plus wrapping against uplifts wall ties need to be drilled into the concrete foundation of the house where possible and will be removed on dismantling. Any remedial work when ties are removed is the client’s responsibility.',
		type: 'Additional Condition',
		checked: false,
	},
	{
		title: 'Asbestos management',
		text: 'As there is asbestos present at the property the client must ensure that the job site including the scaffolding is safe for the iScaff staff to enter for scaffolding checks, alterations and dismantling. iScaff will request a copy of the asbestos management plan and a copy of the test results once asbestos removal is completed.',
		type: 'Additional Condition',
		checked: false,
	},
]
